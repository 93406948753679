import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Handle,
  getOutgoers,
  getIncomers,
} from "react-flow-renderer";
import { useDrop } from "react-dnd";
import { v4 } from "uuid";
import renderImage from "../../renderImage";
import HeaderNavBar from "../../HeaderNavBar";
import BottomBar from "./BottamBar";
import { CustomDragLayer } from "../../CustomDragLayer.js";
import "../../dnd.css";
let globalPosition = [];
function getCoords2(elem) {
  // crossbrowser version
  let handle = [];
  for (let i = 0; i < elem.childNodes.length; i++) {
    if (
      elem.childNodes[i].className.includes("react-flow__handle") &&
      !elem.childNodes[i].className.includes("react-flow__handle-top")
    ) {
      let handle_elem = elem.childNodes[i];
      var box = handle_elem.getBoundingClientRect();

      let temp = {
        id: handle_elem.dataset.handleid,
        x: box.x, //Math.round(left) + 100,
        y: box.y, //Math.round(top),
      };
      handle.push(temp);
    }
  }
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return {
    x: Math.round(left),
    y: Math.round(top) + 30,
    id: elem.dataset.id,
    handle: handle,
  };
}
function getCoords(elem) {
  var box = elem.getBoundingClientRect();
  return {
    x: box.x,
    y: box.y,
    id: elem.dataset.id,
  };
}
let cirOutput = [],
  cirInput = [],
  cirFlag = 0;
const SeriesCircuit = (props) => {
  const text = (type, _id) => {
    if (type === "power") {
      return (
        <>
          <div
            className="Image-render"
            style={{
              backgroundImage: `url(${renderImage("power")})`,
            }}
            id="image-render"
            key={v4()}
          ></div>
          {/* <Handle
            type="source"
            position="right"
            style={{ left: "8.5vw", top: " 4.9vh" }}
            id="p"
          /> */}
          <Handle
            type="target"
            position="right"
            style={{ left: "8.8vw", top: " 7.7vh" }}
            id="n"
          />
          {/* <Handle
            type="target"
            position="right"
            style={{ left: "8.5vw", top: " 9.1vh" }}
            id="r3"
          /> */}
          <Handle
            type="source"
            position="right"
            style={{ left: "8.8vw", top: " 2.9vh" }}
            id="p"
          />
        </>
      );
    }
    if (type === "beeper") {
      // console.log("start");

      return (
        <>
          <div
            className="Image-render"
            style={{
              backgroundImage: `url(${renderImage("beeper")})`,
              bottom: "3.5vh",
            }}
            id="image-render"
            key={v4()}
          ></div>
          <div id={"beeperdndnode_" + _id} style={{}}></div>
          <Handle
            type="target"
            position="left"
            style={{ left: " 0.3vw", top: " 1.9vh" }}
            id="l"
          />
          <Handle
            type="source"
            position="right"
            style={{ left: "10.2vw", top: " 1.9vh" }}
            id="r"
          />
        </>
      );
    }
    if (type === "led") {
      return (
        <>
          <div
            className="Image-render"
            style={{
              backgroundImage: `url(${renderImage("led")})`,
            }}
            id="image-render"
            key={v4()}
          ></div>
          <div id={"leddndnode_" + _id} style={{}}></div>
          <Handle
            type="target"
            position="left"
            style={{ left: " -0.3vw", top: " 5.6vh" }}
            id="l"
          />
          <Handle
            type="source"
            position="right"
            style={{ left: "10.6vw", top: " 5.6vh" }}
            id="r"
          />
        </>
      );
    }
    if (type === "tact") {
      return (
        <>
          <div
            className="Image-render"
            style={{
              backgroundImage: `url(${renderImage("tact")})`,
            }}
            id="image-render"
            key={v4()}
          ></div>
          <button
            onClick={(e) => tactHandler(e, _id)}
            onMouseDownCapture={(e) => tactHandler(e, _id)}
            onTouchStartCapture={(e) => tactHandler(e, _id)}
            // value={tact[_id]}
            style={{
              left: "5.1vw",
              top: "-8.2vh",
              position: "relative",
              transform: "scale(2.7)",
              borderRadius: "50%",
              padding: "0.6vh",
            }}
          ></button>
          <Handle
            type="target"
            position="left"
            style={{ left: " -0.3vw", top: " 5.6vh" }}
            id="l"
          />
          <Handle
            type="source"
            position="right"
            style={{ left: "10.6vw", top: " 5.6vh" }}
            id="r"
          />
        </>
      );
    }
  };
  const initialNodes = [
    {
      id: "dndnode_0",

      type: `output`,
      position: { x: 120, y: 120 },
      data: {
        label: text(`power`, 0),
        specificElType: `power`,
      },
    },
  ];
  let id = 1;
  const getId = () => `dndnode_${id++}`;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const reactFlowWrapper = useRef(null);

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
    },
    [reactFlowInstance]
  );
  const closedChk = async (node, nodes, edges) => {
    let nodeDetail1 = await getOutgoers(node, nodes, edges);
    console.log(nodeDetail1, node, nodes, edges);
    if (nodeDetail1 == undefined || nodeDetail1.length == 0) return false;
    if (
      nodeDetail1[0].data.specificElType == "led" ||
      nodeDetail1[0].data.specificElType == "beeper"
    )
      cirOutput.push(nodeDetail1[0]);
    if (nodeDetail1[0].data.specificElType == "tact")
      cirInput.push(nodeDetail1[0]);
    if (nodeDetail1[0].id == nodes[0].id) return true;
    return closedChk(nodeDetail1[0], nodes, edges);
  };
  const autoConnect = async ({ edge, cursorOnconnect = false }) => {
    if (!cursorOnconnect) {
      setEdges((e) => addEdge(edge, e));
    }
    // let nodeDetail1 = await getOutgoers(nodes[0], nodes, edges);
    cirOutput = [];
    cirInput = [];
    cirFlag = await closedChk(nodes[0], nodes, edges);
    console.log(cirFlag);
    if (cirFlag) {
      if (cirInput.length != 0) return;
      cirOutput.forEach((e) => {
        let eleLed1 = document.getElementById(`led${e.id}`);
        if (e.data.specificElType == "beeper")
          eleLed1 = document.getElementById(`beeper${e.id}`);
        eleLed1.classList.add("led-light");
        eleLed1.style.transform = `scale(${100 / 30} )`;
      });
    }
  };

  const [{}, drop] = useDrop(
    () => ({
      accept: "yellow",
      drop(_item, monitor) {
        let item = JSON.parse(sessionStorage.getItem("connect"));
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();
        if (typeof item === "undefined" || !item) return;
        const pos = monitor.getClientOffset();
        const position = reactFlowInstance.project({
          x: pos.x - reactFlowBounds.left - 104,
          y: pos.y - reactFlowBounds.top - 55,
        });
        const newNode = {
          id: getId(),
          type: `output`,
          position,
          data: {
            label: text(`${item.target_type}`, id - 1),
            specificElType: `${item.target_type}`,
          },
        };
        setNodes((e) => e.concat(newNode));
        // const element = document.getElementsByClassName(
        //   `dndnode_${item.target_type}true`
        // );
        // element[0].style.display = "none";
        if (item.source_id) {
          let edge = {
            source: item.source_id,
            sourceHandle: item.source_handle,
            target: newNode.id,
            targetHandle: item.target_handle,
          };
          if (item.target_handle == "r")
            edge = {
              target: item.source_id,
              targetHandle: item.source_handle,
              source: newNode.id,
              sourceHandle: item.target_handle,
            };

          autoConnect({ edge });
        }
        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType(),
      }),
    }),
    [onDrop]
  );
  const onNodeDrag = async (event, node) => {
    const draggedEle = await getCoords2(event.target.parentElement);
    globalPosition.forEach((x) => {
      if (draggedEle.id !== x.id) {
        x.handle.forEach((e) => {
          draggedEle.handle.forEach((ele) => {
            if (Math.abs(ele.x - e.x) <= 4 && Math.abs(ele.y - e.y) <= 4) {
              let edge = {
                source: x.id,
                sourceHandle: e.id,
                target: draggedEle.id,
                targetHandle: ele.id,
              };
              if (ele.id == "r")
                edge = {
                  target: x.id,
                  targetHandle: e.id,
                  source: draggedEle.id,
                  sourceHandle: ele.id,
                };

              autoConnect({ edge });
            }
          });
        });
      }
    });
  };

  const onConnect = async (params, event) => {
    var index1 = await edges.findIndex(
      (e) =>
        e.source === params.source && e.sourceHandle === params.sourceHandle
    );
    var index2 = await edges.findIndex(
      (e) =>
        e.target === params.target && e.targetHandle === params.targetHandle
    );

    switch (props.type) {
      default:
        if (index1 != -1) {
          // debugger;
          return;
        }
        if (index2 != -1) {
          // debugger;
          return;
        }
        await setEdges((eds) => addEdge(params, eds));

        return;
    }

    return;
  };
  const onSelectionDrag = async (event) => {
    console.log(event);
  };
  const onNodeDragStart = async (event) => {
    globalPosition = [];
    let global = document.getElementsByClassName("react-flow__nodes")[0];
    try {
      global = global.childNodes;
      for (let i = 0; i < global.length; i++) {
        let temp = await getCoords(global[i]);
        let temp_freedom = await getCoords2(global[i]);
        globalPosition.push(temp_freedom);
      }
    } catch (e) {}
  };

  useEffect(() => {
    autoConnect({ edge: "", cursorOnconnect: true });
  }, [edges]);

  const tactHandler = async (ele, id) => {
    console.log(ele.type, cirFlag, cirInput.length);
    // alert("hello");
    if (cirFlag) {
      if (cirInput.length == 0) return;
      cirOutput.forEach((e) => {
        let eleLed1 = document.getElementById(`led${e.id}`);
        if (e.data.specificElType == "beeper")
          eleLed1 = document.getElementById(`beeper${e.id}`);
        if (ele.type == "mousedown" || ele.type == "touchstart") {
          eleLed1.classList.add("led-light");
          eleLed1.style.transform = `scale(${100 / 30} )`;
        } else {
          eleLed1.classList.remove("led-light");
        }
      });
    }
  };
  const onEdgeClickHandel = async (e, toDeleteEdge) => {
    var index = await edges.findIndex((e) => e.id === toDeleteEdge.id);
    if (index != -1) {
      await setEdges(await edges.filter((node) => node.id !== toDeleteEdge.id));
      cirOutput = [];
      cirInput = [];
      cirOutput.forEach((e) => {
        let eleLed1 = document.getElementById(`led${e.id}`);
        if (e.data.specificElType == "beeper")
          eleLed1 = document.getElementById(`beeper${e.id}`);
        eleLed1.classList.remove("led-light");
      });
    }
  };

  return (
    <div className="dndflow">
      <HeaderNavBar />
      <div id="body">
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onInit={setReactFlowInstance}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onScroll={onSelectionDrag}
              onNodeDrag={onNodeDrag}
              onNodeDragStart={onNodeDragStart}
              // onEdgeDoubleClick={onEdgeDoubleClick}
              onEdgeClick={onEdgeClickHandel}
              ref={drop}
              zoomOnScroll={true}
              onDrop={onDrop}
              // onElementClick={onElementClick}
            >
              <svg
                id="svg"
                width="1500"
                height="800"
                className="react-flow__edges"
              >
                <path
                  id="path"
                  stroke="#09b8a7"
                  fill="none"
                  strokeWidth="4"
                ></path>
              </svg>
            </ReactFlow>
          </div>
          <BottomBar />
          <CustomDragLayer />
        </ReactFlowProvider>
      </div>
    </div>
  );
};
export default SeriesCircuit;
