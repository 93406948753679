import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDrag } from "react-dnd";
import Slider from "react-slick";
import { getEmptyImage } from "react-dnd-html5-backend";

import SliderBackArrow from "../../SliderBackArrow";
import SliderNextArrow from "../../SliderNextArrow";
import renderImage from "../../renderImage";
//sidebar logic
function getCoords2(elem) {
  // crossbrowser version
  let handle = [];
  for (let i = 0; i < elem.childNodes.length; i++) {
    if (
      elem.childNodes[i].className.includes("react-flow__handle") &&
      !elem.childNodes[i].className.includes("react-flow__handle-top")
    ) {
      let handle_elem = elem.childNodes[i];
      var box = handle_elem.getBoundingClientRect();

      let temp = {
        id: handle_elem.dataset.handleid,
        x: box.x, //Math.round(left) + 100,
        y: box.y, //Math.round(top),
      };
      handle.push(temp);
    }
  }
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return {
    x: Math.round(left),
    y: Math.round(top) + 30,
    id: elem.dataset.id,
    handle: handle,
  };
}
function getCoords(elem) {
  var box = elem.getBoundingClientRect();
  return {
    x: box.x,
    y: box.y,
    id: elem.dataset.id,
  };
}
sessionStorage.setItem(
  "seriesCircuitLedCount",
  JSON.parse(sessionStorage.getItem("seriesCircuitLedCount")) || 0
);

sessionStorage.setItem("seriesCircuitBeeperCount", 0);
sessionStorage.setItem("junctionCount", 10);
let nodes,
  edges,
  flagI = -1,
  globalpass = [],
  node_cor = [],
  seriesCircuitLedCount = 0,
  seriesCircuitBeeperCount = 0,
  junctionCount = 0;
//end
const current_node_cord = (ele) => {
  try {
    let element = document.getElementById("dragLayer" + ele);

    let han = element.childNodes;
    let handle = [];
    for (let j = 0; j < han.length; j++) {
      if (
        han[j].className.includes("react-flow__handle") ||
        han[j].className.includes("react-flow__handle-top")
      ) {
        let handle_elem = han[j];
        var box = handle_elem.getBoundingClientRect();

        let temp = {
          id: handle_elem.dataset.handleid,
          x: box.x, //Math.round(left),
          y: box.y, //Math.round(top),
        };
        handle.push(temp);
      }
    }

    return handle;
  } catch (e) {}
};
let globalPosition = [];
let dragStartFlag = 0;
const BottomBar = (props) => {
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileAgents = [
      "iphone",
      "ipad",
      "android",
      "blackberry",
      "windows phone",
    ];
    mobileAgents.forEach(function (agent) {
      if (userAgent.indexOf(agent) > -1) setIsMobile(true);
    });
  }, []);
  let title, left, top;
  const [id, setId] = useState("nan");
  const [handle, setHandle] = useState(0);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "yellow",
      item: { id, handle, top, title },
      canDrag: true,
      collect: (monitor) => ({
        isDragging: monitor,
      }),
    }),
    [id, handle, top, title]
  );
  const eles = document.querySelectorAll("[data-type]");
  const onDragStart = async (event, nodeType) => {
    console.log("hello boys");
    item = {
      source_id: null,
      source_handle: null,
      target_handle: null,
      target_type: nodeType.replace(/\d/, ""),
    };
    sessionStorage.setItem("connect", JSON.stringify(item));
    globalPosition = [];
    setId(await event.target.attributes[2].nodeValue);
    let global = document.getElementsByClassName("react-flow__nodes")[0];

    try {
      global = global.childNodes;
      for (let i = 0; i < global.length; i++) {
        let temp = await getCoords(global[i]);
        let temp_freedom = await getCoords2(global[i]);
        globalPosition.push(temp_freedom);
      }
    } catch (e) {}
    var body = document.body;
    var docEl = document.documentElement;
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;
  };
  const onDragEnd = async (event, nodeType) => {
    var path = document.getElementById("path");
    path.setAttribute("d", "");
    setId("nan");
    sessionStorage.removeItem("connect");

    item = null;
    dragStartFlag = 0;
  };
  const draw = (ux, uy, vx, vy) => {
    var startX = ux;
    var startY = uy - 100;
    var endX = vx;
    var endY = vy - 100;
    if (isMobile) {
      startX = ux + 1;
      startY = uy - 55;
      endX = vx + 1;
      endY = vy - 55;
    }
    var controlX = (startX + endX + 15) / 2;
    var controlY = (startY + endY - 15) / 2;

    // Select the existing path element
    var path = document.getElementById("path");

    // Create the path data
    var pathData =
      "M" +
      startX +
      " " +
      startY +
      " Q" +
      controlX +
      " " +
      controlY +
      "," +
      endX +
      " " +
      endY;
    path.setAttribute("d", pathData);
  };
  let item;
  const onDrag = async (event, nodeType) => {
    const element = document.getElementById(`${nodeType}`);
    element.style.display = "none";
    let cur_cord = (await current_node_cord(nodeType.replace(/\d/, ""))) || [];
    // console.log(cur_cord);
    globalPosition.forEach((x) => {
      // console.log(e.handle, event.clientX, event.clientY, "gsk roll");
      x.handle.forEach((e) => {
        cur_cord.forEach((ele) => {
          // console.log("gsk", ele, e);
          if (
            ele.x - e.x <= 4 &&
            ele.x - e.x >= -4 &&
            ele.y - e.y <= 4 &&
            ele.y - e.y >= -4
          ) {
            dragStartFlag = { x: e.x, y: e.y, id: ele.id };
            item = {
              source_id: x.id,
              source_handle: e.id,
              target_handle: ele.id,
              target_type: nodeType.replace(/\d/, ""),
            };
            sessionStorage.setItem("connect", JSON.stringify(item));
          }
        });
      });
      if (dragStartFlag != 0) {
        cur_cord.forEach((ele) => {
          if (ele.id == dragStartFlag.id) {
            // console.log("gsk", dragStartFlag.x, dragStartFlag.y, ele.x, ele.y);
            draw(dragStartFlag.x, dragStartFlag.y, ele.x, ele.y);
          }
        });
      }
    });
  };

  const onMouseEnter = async (event) => {
    setId(await event.target.attributes[2].nodeValue);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderBackArrow />,
  };
  return (
    <aside>
      <div
        style={{
          overflow: "hidden",
          background: "#b1b1b747",
          borderRadius: "12px",
          margin: "0 11%",
        }}
        ref={drag}
      >
        <Slider {...settings} style={{ width: "100vh" }}>
          <div
            className={"dndnode_beeper" + true}
            value="beeper"
            data-type="beeper"
            draggable
            id="beeper1"
            style={{}}
            onDragEnd={(event) => onDragEnd(event, "beeper1")}
            onTouchEnd={(event) => onDragEnd(event, "beeper1")}
            onDragStart={(event) => onDragStart(event, "beeper1")}
            onTouchStart={(event) => onDragStart(event, "beeper1")}
            onDrag={(event) => onDrag(event, "beeper1")}
            onTouchMove={(event) => onDrag(event, "beeper1")}
            onMouseEnter={onMouseEnter}
          ></div>
          <div
            style={{}}
            className={"dndnode_junction" + true}
            draggable
            data-type="junction"
            id="junction1"
            onDragEnd={(event) => onDragEnd(event, "junction1")}
            onTouchEnd={(event) => onDragEnd(event, "junction1")}
            onDragStart={(event) => onDragStart(event, "junction1")}
            onTouchStart={(event) => onDragStart(event, "junction1")}
            onDrag={(event) => onDrag(event, "junction1")}
            onTouchMove={(event) => onDrag(event, "junction1")}
            onMouseEnter={onMouseEnter}
          ></div>
          <div
            className={"dndnode_beeper" + true}
            value="beeper"
            data-type="beeper"
            draggable
            id="beeper2"
            style={{}}
            onDragEnd={(event) => onDragEnd(event, "beeper2")}
            onTouchEnd={(event) => onDragEnd(event, "beeper2")}
            onDragStart={(event) => onDragStart(event, "beeper2")}
            onTouchStart={(event) => onDragStart(event, "beeper2")}
            onDrag={(event) => onDrag(event, "beeper2")}
            onTouchMove={(event) => onDrag(event, "beeper2")}
            onMouseEnter={onMouseEnter}
          ></div>
          <div
            style={{}}
            // onDrag={(event) => onDrag(event, "led")}
            onDragEnd={(event) => onDragEnd(event, "led")}
            className={"dndnode_led" + true}
            draggable
            data-type="led"
            id="led1"
            onDragStart={(event) => onDragStart(event, "led1")}
            onMouseEnter={onMouseEnter}
            onDrag={(event) => onDrag(event, "led1")}
            onTouchStart={(event) => onDragStart(event, "led1")}
            onTouchEnd={(event) => onDragEnd(event, "led1")}
            onTouchMove={(event) => onDrag(event, "led1")}
          ></div>
          <div
            style={{}}
            className={"dndnode_junction" + true}
            draggable
            data-type="junction"
            id="junction2"
            onDragEnd={(event) => onDragEnd(event, "junction2")}
            onTouchEnd={(event) => onDragEnd(event, "junction2")}
            onDragStart={(event) => onDragStart(event, "junction2")}
            onTouchStart={(event) => onDragStart(event, "junction2")}
            onDrag={(event) => onDrag(event, "junction2")}
            onTouchMove={(event) => onDrag(event, "junction2")}
            onMouseEnter={onMouseEnter}
          ></div>
          <div
            style={{}}
            // onDrag={(event) => onDrag(event, "led")}
            onDragEnd={(event) => onDragEnd(event, "led2")}
            className={"dndnode_led" + true}
            draggable
            data-type="led"
            id="led2"
            onDragStart={(event) => onDragStart(event, "led2")}
            onMouseEnter={onMouseEnter}
            onDrag={(event) => onDrag(event, "led2")}
            onTouchStart={(event) => onDragStart(event, "led2")}
            onTouchEnd={(event) => onDragEnd(event, "led2")}
            onTouchMove={(event) => onDrag(event, "led2")}
          ></div>
          <div
            style={{}}
            className={"dndnode_tact" + true}
            draggable
            data-type="tact"
            id="tact"
            onDragStart={(event) => onDragStart(event, "tact")}
            onMouseEnter={onMouseEnter}
            onDrag={(event) => onDrag(event, "tact")}
            onDragEnd={(event) => onDragEnd(event, "tact")}
            onTouchStart={(event) => onDragStart(event, "tact")}
            onTouchEnd={(event) => onDragEnd(event, "tact")}
            onTouchMove={(event) => onDrag(event, "tact")}
          ></div>
          <div className="dummy"></div>
        </Slider>
      </div>
    </aside>
  );
};
export default BottomBar;
